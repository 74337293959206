import { Suspense, lazy } from "react";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";

import { Route, HashRouter as Router, Routes } from "react-router-dom";

import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const HomePage = lazy(() => import("../pages/Home/HomePage"));
const Portfolio = lazy(() => import("../pages/Portfolio/Portfolio"));
const SingleWorkPage = lazy(() =>
    import("../pages/SingleWorkPage/SingleWorkPage")
);
const BlogPage = lazy(() => import("../pages/Blog/BlogPage"));

const App = () => {
    const isMenuOpen = useSelector((state) => state.isMenuOpen);

    return (
        <>
            <Router>
                <Header />
                {isMenuOpen ? <Menu /> : null}
                <Suspense
                    fallback={
                        <CircularProgress
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translateY(-50%) translateX(-50%)",
                            }}
                            size={"md"}
                        />
                    }
                >
                    <Routes>
                        <Route path={"/blog"} element={<BlogPage />} />
                        <Route path={"/"} element={<HomePage />} />
                        <Route path={"/portfolio"} element={<Portfolio />} />
                        <Route
                            path={"/photo/:id"}
                            element={<SingleWorkPage filter="photo" />}
                        />
                        <Route
                            path={"/video/:id"}
                            element={<SingleWorkPage filter="video" isVideo />}
                        />
                        <Route
                            path={"/clip/:id"}
                            element={<SingleWorkPage filter="clip" isVideo />}
                        />
                        <Route
                            path={"/youtube/:id"}
                            element={
                                <SingleWorkPage filter="youtube" isVideo />
                            }
                        />
                        <Route
                            path={"/vertical/:id"}
                            element={
                                <SingleWorkPage filter="vertical" isVideo />
                            }
                        />
                        <Route
                            path={"/reportage/:id"}
                            element={
                                <SingleWorkPage filter="reportage" isVideo />
                            }
                        />
                    </Routes>
                </Suspense>
                {!isMenuOpen ? <Footer /> : null}
            </Router>
        </>
    );
};

export default App;
